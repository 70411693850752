<template>
  <div class="admin-container">
    <template>
      <v-row>
        <v-col cols="12" md="12">
          <v-btn
            tile
            small
            @click="
              $router.push({
                name: 'Payments',
              })
            "
            >Zpět</v-btn
          >
        </v-col>
      </v-row>
      <h3 class="mt-5 mb-8 col-secodary-2">Platba</h3>
      <v-row>
        <v-col cols="12" md="3"
          ><v-text-field
            v-model="data.name"
            label="Název platby *"
          ></v-text-field
        ></v-col>
        <v-col cols="12" md="3">
          <v-select
            :items="statuses"
            item-text="name"
            item-value="status"
            label="Stav platby"
            v-model="data.status"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="3">
          <v-select
            :items="payments"
            label="Pořadí platby"
            v-model="data.behind_payment"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <v-textarea
            v-model="data.description"
            label="Poznámka"
            auto-grow
          ></v-textarea>
        </v-col>
      </v-row>

      <input
        style="display: none"
        ref="documentFile"
        type="file"
        class="input--file"
        multiple
        @input="handleInputFile($event.target.files)"
      />
      <input
        style="display: none"
        ref="invoiceFile"
        type="file"
        class="input--file"
        @input="handleInputFile($event.target.files[0], true)"
      />

      <div
        class="d-flex flex-column flex-md-row align-center align-md-start doc-wrapper"
      >
        <div v-if="invoice_document_id">
          <div class="document-added mr-md-6">
            <div
              class="document-document-mini"
              :style="correctIconInvoice()"
            ></div>
            <div class="document-extension">
              {{ invoice_extension ? invoice_extension.toUpperCase() : '' }}
            </div>
            <div
              class="document-text document-text--overflow document-text--name mt-2"
            >
              {{ invoice_client_name }}
            </div>
            <div class="document-close">
              <button
                @click="handleDeleteFile(invoice_document_id, null, true)"
              >
                <img :src="require('@/assets/zavrit.svg')" alt="" />
              </button>
            </div>
          </div>
        </div>
        <button class="document-input-add" @click="handleClick('invoiceFile')">
          <div class="document-input-add-plus">+</div>
          <div class="document-input-add-text">Vložit fakturu</div>
        </button>
      </div>

      <div
        class="d-flex flex-column flex-md-row align-center align-md-start doc-wrapper"
      >
        <div
          v-for="(document, documentIdx) in documents"
          :key="document.document_id"
        >
          <div class="document-added mr-md-6">
            <div
              class="document-document-mini"
              :style="correctIcon(document)"
            ></div>
            <div class="document-extension">
              {{ document.extension ? document.extension.toUpperCase() : '' }}
            </div>
            <!-- <div class="document-text">
              Dokument <br />{{ document.mimetype }}
            </div> -->
            <div class="document-close">
              <button
                @click="handleDeleteFile(document.document_id, documentIdx)"
              >
                <img :src="require('@/assets/zavrit.svg')" alt="" />
              </button>
            </div>
          </div>
          <v-text-field
            style="width: 150px"
            v-model="document.name"
            label="Název souboru *"
          ></v-text-field>
        </div>
        <button class="document-input-add" @click="handleClick('documentFile')">
          <div class="document-input-add-plus">+</div>
          <div class="document-input-add-text">Vložit dokument</div>
        </button>
      </div>
      <v-row>
        <v-col cols="12" md="3"></v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="3"></v-col>
      </v-row>
      <v-row class="mt-8">
        <v-col class="text-right">
          <v-btn
            small
            tile
            color="secondary"
            class="mr-4"
            @click="
              $router.push({
                name: 'Payments',
              })
            "
            >Zrušit</v-btn
          >
          <v-btn small tile color="primary" @click="store">Uložit</v-btn>
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
import bus from '../plugins/bus';
import authHandler from '../api/authHandler';
import permissionDenied from '../api/permissionDenied';
import errorHandler from '../api/errorHandler';
import documentService from '../api/documentService';
import paymentService from '../api/paymentService';
const IMAGES_EXT = ['jpg', 'jpeg', 'svg', 'png'];
const PDF = 'pdf';
const ZIP = 'zip';
const PPT = 'ppt';
const XLS = 'xls';

export default {
  data() {
    return {
      data: {},
      invoice_document_id: null,
      documents: [],
      statuses: [],
      payments: [],
      invoice_document_src: null,
      invoice_document_mimetype: null,
      invoice_extension: null,
      invoice_client_name: null,
    };
  },
  async created() {
    permissionDenied(
      this.user,
      this.$router,
      'SUPERUSER',
      'PROJECT_MANAGER',
      'MAIN_SUPERUSER',
      'MAIN_PROJECT_MANAGER'
    );
    bus.$emit('processing');
    await this.getPayments();
    await this.getPaymentStatuses();
    bus.$emit('processing', false);
  },
  methods: {
    correctIconInvoice() {
      let icon = 'document.svg';
      if (IMAGES_EXT.includes(this.invoice_extension)) icon = 'jpg.svg';
      if (PDF == this.invoice_extension) icon = 'pdf.svg';
      if (this.invoice_extension.includes(ZIP)) icon = 'zip.svg';
      if (this.invoice_extension.includes(PPT)) icon = 'ppt.svg';
      if (this.invoice_extension.includes(XLS)) icon = 'xls.svg';
      return {
        backgroundImage: `url(${require('@/assets/' + icon)})`,
      };
    },
    correctIcon(doc) {
      let icon = 'document.svg';
      if (IMAGES_EXT.includes(doc.extension)) icon = 'jpg.svg';
      if (PDF == doc.extension) icon = 'pdf.svg';
      if (doc.extension.includes(ZIP)) icon = 'zip.svg';
      if (doc.extension.includes(PPT)) icon = 'ppt.svg';
      if (doc.extension.includes(XLS)) icon = 'xls.svg';
      return {
        backgroundImage: `url(${require('@/assets/' + icon)})`,
      };
    },
    async getPayments() {
      try {
        const data = await paymentService.index();
        for (let i = 0; i < data.length; i++) {
          this.payments.push(i + 1);
        }
        this.payments.push(this.payments.length + 1);
        this.data.behind_payment = this.payments[this.payments.length - 1];
        // this.payments = data;
        //   if (data.length) {
        //   let sortedData = data.sort((a, b) => a.id - b.id);
        //   this.data.behind_payment =
        //     sortedData[sortedData.length - 1]["id"];
        // }
      } catch (error) {
        errorHandler(error);
      }
    },
    async getPaymentStatuses() {
      try {
        const data = await paymentService.statuses();
        this.statuses = data;
      } catch (error) {
        errorHandler(error);
      }
    },
    async handleDeleteFile(documentId, documentIdx, arg = null) {
      try {
        bus.$emit('processing');
        await documentService._delete(documentId);
        if (arg == null) {
          this.documents.splice(documentIdx, 1);
        } else {
          this.invoice_document_id = null;
          console.debug('data', this.data);
        }
        bus.$emit('processing', false);
      } catch (error) {
        bus.$emit('processing', false);
        console.error(error);
        errorHandler(error);
      }
    },
    handleClick(ref) {
      this.$refs[ref].click();
    },
    async handleInputFile(f, arg = null) {
      try {
        console.debug(f);
        bus.$emit('processing');
        if (arg == null) {
          for (let fidx = 0; fidx < f.length; fidx++) {
            var { data } = await documentService.store(f[fidx]);
            this.documents.push({
              document_id: data.id,
              name: data.client_name,
              mimetype: data.mimetype,
              mime: data.mime,
              extension: data.extension,
            });
          }
          this.$refs.documentFile.values = [];
        } else {
          var { data } = await documentService.store(f);
          console.debug('set invoice');
          this.invoice_document_id = data.id;
          this.invoice_document_src = data.document_path;
          this.invoice_document_mimetype = data.mimetype;
          this.invoice_extension = data.extension;
          this.invoice_client_name = data.client_name;
          console.debug('data', this.data);
        }
        bus.$emit('processing', false);
        bus.$emit('snackbar', {
          text: `Podařilo se nahrát dokumenty`,
          color: 'info',
        });
      } catch (error) {
        bus.$emit('processing', false);
        console.error(error);
        errorHandler(error);
      }
    },
    async indexTimelines() {
      try {
        const data = await timelineService.index();
        this.timelines = data;
      } catch (error) {
        console.error(error);
        errorHandler(error);
      }
    },
    async store() {
      try {
        bus.$emit('processing');
        const response = await paymentService.store({
          ...this.data,
          invoice_document_id: this.invoice_document_id,
          documents: this.documents,
        });
        console.debug('Response', response);
        bus.$emit('processing', false);
        bus.$emit('snackbar', {
          text: 'Uloženo',
          color: 'success',
        });
        this.$router.push({
          name: 'Payments',
        });
      } catch (error) {
        console.error(error);
        bus.$emit('processing', false);
        if (
          error.response &&
          error.response.data &&
          error.response.data.error.message
        ) {
          bus.$emit('snackbar', {
            text: error.response.data.error.message,
            color: 'error',
          });
        }
      }
    },
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
  },
  watch: {},
};
</script>

<style></style>
